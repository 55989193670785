<template>
  <v-data-table :headers="headers" :items="adminRequests" class="elevation-1">
    <template v-slot:top>
      <v-toolbar text>
        <v-toolbar-title>Admin Requests</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="900px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.full_name"
                      label="Name" disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email" disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.address"
                      label="Address" disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.city"
                      label="City" disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.state"
                      label="State" disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.zip"
                      label="Zip" disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.phoneNumber"
                      label="Phone number" disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.birthday"
                      label="Birthday" disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-model="editedItem.sex"
                      label="Gender" disabled
                      :items="genders"
                      item-text="text"
                      item-value="value"
                    ></v-select>
                  </v-col>
                  <v-col v-if="editedIndex !== -1" cols="12" sm="6" md="4">
                    <v-switch
                      :disabled="editedItem.processed"
                      inset
                      v-model="editedItem.accepted"
                      label="Accepted"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card v-if="editedIndex !== -1">
            <v-card-title class="text-h5"
              >{{
                `Are you sure you want to delete ${adminRequests[editedIndex].full_name}`
              }}?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
    </template>
    <template v-slot:no-data>
      <span>No requests created</span>
    </template>
  </v-data-table>
</template>

<script>
import AdminRequestService from "../../services/AdminRequestService";

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "full_name",
      },
      { text: "Email", value: "email" },
      { text: "Processed", value: "processed" },
      { text: "Accepted", value: "accepted" },
      { text: "Created", value: "createdAt" },
      { text: "Updated", value: "updatedAt" },

      { text: "Actions", value: "actions", sortable: false },
    ],
    adminRequests: [],
    editedIndex: -1,
    editedItem: {
      _id: null,
      full_name: "",
      email: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phoneNumber: "",
      birthday: "",
      sex: "",
      processed: "",
      accepted: "",
    },
    defaultItem: {
      _id: null,
      full_name: "",
      email: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phoneNumber: "",
      birthday: "",
      sex: "",
      processed: "",
      accepted: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Admin Request" : "Edit Admin Request";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.adminRequests.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.adminRequests.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.adminRequests.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async getRequests() {
      const response = await AdminRequestService.getRequests();
      this.adminRequests = response.data.data;
    },

    async save() {
      this.editedItem.processed = true;
      const response = await AdminRequestService.updateRequest(this.editedItem._id, this.editedItem);
      console.log(response.data);
      this.close();
    },
  },
  mounted() {
    this.getRequests();
  }
};
</script>

<style>
</style>